<template>
    <div>

        <SketchUpComponentDialog v-if="showSketchUpComponentDialog" v-bind="sketchUpComponentDialogParameters"
                                 v-on:hidden="showSketchUpComponentDialog = false"
                                 v-on:select-component="selectComponent"
                                 v-on:zoom-component="zoomComponent"
                                 v-on:zoom-reset="zoomReset"/>

        <div class="card st-framework-card">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                            <font-awesome-icon :icon="icon" size="sm"/>
                        </td>
                        <td style="text-align: center;">
                            {{ $i18n.tc('translations.' + name, 2) + " (" + (project ? project.label : $i18n.t('translations.No Project Found')) + ")" }}
                        </td>
                        <td style="width: 50px;"/>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0" v-if="pageLoaded">

                <div class="card" style="border: none;">
                    <div class="card-header light" style="border: none;">
                        <div class="float-right" style="display: inline-block;">
                            <template v-for="(action,actionIndex) in actions.document">
                                <ButtonAction :key="action.name+'-'+actionIndex"
                                              :action="action" :disabled="state.disabled"
                                              v-on:generate-dxf-files="launchPunchPlates"
                                              v-if="action.type === 'BUTTON' && action.placement === 'DOCUMENTHEADERRIGHT'"/>
                            </template>
                        </div>
                    </div>
                </div>

                <Table :key="'component-table-'+tableReload" header-class="light" footer-class="light" body-class=""
                       with-filter with-pagination pagination-always-visible no-state-loading autofocus :lines-per-page=35
                       :rows="processedComponentList"
                       :fields="fields.table" :actions="actions.table" :state="state"
                       v-on:clear-selection="clearSelection"
                       v-on:delete-selection="deleteSelection"
                       v-on:detail-components="detailComponents"
                       v-on:download-event="download"
                       v-on:refresh="getPunchPlates2"
                       v-on:select-all="selectAll"
                       v-on:select-components="selectComponent"
                       v-on:set-ignore-flipped="setIgnoreFlipped"
                       v-on:zoom-component="zoomComponent"
                       v-on:zoom-reset="zoomReset"/>

            </div>

        </div>
    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction.vue";
import FileDownload from "@/mixins/FileDownload";
import SketchUpComponentDialog from "@/components/dialogs/SketchUpComponentDialog.vue";
import Table from "@/components/Table";

/* global sketchup:false */

export default {
    name: 'PunchPlates2',
    components: {
        ButtonAction,
        SketchUpComponentDialog,
        Table,
    },
    props: ['name', 'icon', 'api', 'state'],
    mixins: [FileDownload],
    data() {
        return {
            pageLoaded: false,
            project: null,
            fields: null,
            actions: null,
            punchPlateUtilityVersion: "",
            dropBoxLocation: "",
            processedComponentList: [],
            filterMaterial: null,
            ignoreFlipped: {
                ignoreFlippedFolded: false,
                ignoreFlippedUnfolded: false,
            },
            tableReload: 0,
            showSketchUpComponentDialog: false,
            sketchUpComponentDialogParameters: {
                title: null,
                actions: null,
                fields: null,
                rows: null,
            },
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        sketchup.getToolData();
    },
    methods: {
        clearSelection() {
            sketchup.clearSelection();
        },
        deleteSelection(data) {
            data.ids.forEach(id => {
                let idx = this.processedComponentList.findIndex(component => component.id === id);
                this.processedComponentList.splice(idx, 1);
            });
            this.tableReload++;
        },
        detailComponents(component) {
            let rows = [];
            component.entity_ids.forEach(entityID => {
                let instanceIdx = rows.findIndex(row => row.entity_id === entityID);
                if (instanceIdx === -1) {
                    rows.push({
                        id: rows.length + 1,
                        entity_id: entityID,
                        instances: 1,
                        flipped: component.flipped,
                    });
                } else {
                    rows[instanceIdx].instances++;
                }
            });
            this.sketchUpComponentDialogParameters.title = component.definition;
            this.sketchUpComponentDialogParameters.actions = this.actions.detail;
            this.sketchUpComponentDialogParameters.fields = this.fields.detail;
            this.sketchUpComponentDialogParameters.rows = rows;
            this.showSketchUpComponentDialog = true;
        },
        download(choice) {
            this.state.loading = true;
            let components = [];
            this.processedComponentList.forEach(component => {
                components.push({
                    definition: component.definition,
                    instances_in_model: component.instances_in_model,
                    instances_in_selection: component.instances_in_selection,
                    thickness: component.thickness,
                    cnt_faces: component.cnt_faces,
                    circles: component.circles,
                });
            });
            this.$http.post(choice.api, {
                'components': components,
            }, {'responseType': 'blob'}).then((res) => {
                this.fileDownload(choice, res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("PunchPlates2:download():error:", error);
            });
        },
        fetchData(projectId) {
            this.$http.get(this.api + '/manage_punchplates2/' + projectId, {}).then((res) => {
                this.project = res.data.project;
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.punchPlateUtilityVersion = res.data.punchPlateUtilityVersion;
                this.filterMaterial = res.data.filterMaterial;
                this.dropBoxLocation = res.data.dropBoxLocation;
                sketchup.getPunchPlates2(this.filterMaterial);
            }).catch((error) => {
                console.log("PunchPlates2:fetchData():error:", error);
            });
        },
        getPunchPlates2(data) {
            this.state.loading = true;
            this.filterMaterial = data['filter_material'];

            this.$http.post('/sketchup/add_material_filter_option', {
                project_id: this.project ? this.project.id : null,
                filter_material: this.filterMaterial,
                ignore_flipped_folded: this.ignoreFlipped.ignoreFlippedFolded,
                ignore_flipped_unfolded: this.ignoreFlipped.ignoreFlippedUnfolded,
            }, {}).then((res) => {
                this.actions = {...this.actions, ...res.data.actions};
                sketchup.getPunchPlates2(this.filterMaterial);
            }).catch((error) => {
                console.log("PunchPlates2:getPunchPlates2():error:", error);
            });
        },
        getPunchPlates2Callback(entities) {
            this.$worker.run((rawComponentList, ignoreFlippedFolded, ignoreFlippedNonfolded) => {
                let newProcessedComponentList = [];

                rawComponentList.forEach(component => {
                    let newComponent = component;
                    newComponent.id = newProcessedComponentList.length + 1;
                    newComponent.selected = false;
                    newComponent.components_in_selection = new Set(newComponent.entity_ids).size;

                    newComponent.folds = 0;
                    let circles = [];
                    newComponent['faces'].forEach(face => {
                        face.loops.forEach(loop => {
                            if (loop.circle) {
                                let diameter = Math.round(loop.arccurve.radius * 200) / 100;
                                let i = circles.findIndex(circle => circle.diameter === diameter);
                                if (i === -1) {
                                    circles.push({
                                        'diameter': diameter,
                                        'count': 1,
                                    })
                                } else {
                                    circles[i].count++;
                                }
                            }
                        });
                        newComponent.folds += face.folds.length / 2;
                    });
                    newComponent.circles = '';
                    circles.forEach(circle => {
                        let c = circle.count + ' x ' + circle.diameter.toString().replace('.', ',') + ' mm';
                        newComponent.circles += (newComponent.circles ? ', ' + c : c);
                    });

                    if (newComponent.folds_errors > 0) {
                        newComponent.folds_style = "background: red; color: white;";
                    }

                    if (newComponent.orientation_errors > 0) {
                        newComponent.orientation_errors_style = "background: red; color: white;";
                    }

                    newComponent.flipped = (newComponent.is_flipped === 1) ? 'X' : '';
                    if ((ignoreFlippedFolded && newComponent.folds > 0) ||
                        (ignoreFlippedNonfolded && newComponent.folds === 0)) {
                        if (newComponent.is_flipped === 1) {
                            newComponent.flipped_style = "background: orange; color: white;";
                        }

                        let j = newProcessedComponentList.findIndex(item => (item.definition === newComponent.definition));
                        if (j !== -1) {
                            newProcessedComponentList[j].entity_ids = newProcessedComponentList[j].entity_ids.concat(newComponent.entity_ids);
                            newProcessedComponentList[j].components_in_selection = new Set(newProcessedComponentList[j].entity_ids).size;
                            newProcessedComponentList[j].instances_in_selection += newComponent.instances_in_selection;
                            if (newProcessedComponentList[j].folds_errors > 0 || newComponent.folds_errors > 0) {
                                newProcessedComponentList[j].folds = '?';
                                newProcessedComponentList[j].folds_style = "background: red; color: white;";
                            }
                            if (newProcessedComponentList[j].orientation_errors > 0 || newComponent.orientation_errors > 0) {
                                newProcessedComponentList[j].orientation_errors = '?';
                                newProcessedComponentList[j].orientation_errors_style = "background: red; color: white;";
                            }
                            if (newProcessedComponentList[j].is_flipped !== newComponent.is_flipped) {
                                newProcessedComponentList[j].flipped = '!';
                                newProcessedComponentList[j].flipped_style = "background: orange; color: white;";
                            }
                        } else {
                            newProcessedComponentList.push(newComponent);
                        }
                    } else {
                        if (newComponent.is_flipped === 1) {
                            newComponent.flipped_style = "background: red; color: white;";
                        }

                        let j = newProcessedComponentList.findIndex(item =>
                            (item.definition === newComponent.definition) &&
                            (item.is_flipped === (newComponent.is_flipped === 1 ? 0 : 1))
                        );
                        if (j !== -1) {
                            if (newComponent.is_flipped === 1) {
                                newComponent.definition_style = 'background: red; color: white;';
                            } else {
                                newProcessedComponentList[j].definition_style = 'background: red; color: white;';
                            }
                        }

                        newProcessedComponentList.push(newComponent);
                    }
                });

                newProcessedComponentList.sort(function (a, b) {
                    let idxA = a.definition.lastIndexOf('#');
                    let idxB = b.definition.lastIndexOf('#');
                    if (idxA > -1 && idxB > -1 && a.definition.substring(0, idxA) === b.definition.substring(0, idxB)) {
                        if (parseInt(a.definition.substring(idxA + 1)) < parseInt(b.definition.substring(idxB + 1))) return -1;
                        if (parseInt(a.definition.substring(idxA + 1)) > parseInt(b.definition.substring(idxB + 1))) return 1;
                    } else {
                        if (a.definition < b.definition) return -1;
                        if (a.definition > b.definition) return 1;
                    }
                    if (a.is_flipped < b.is_flipped) return -1;
                    if (a.is_flipped > b.is_flipped) return 1;
                    return 0;
                });

                return newProcessedComponentList;
            }, [JSON.parse(entities), this.ignoreFlipped.ignoreFlippedFolded, this.ignoreFlipped.ignoreFlippedUnfolded]).then(data => {
                this.processedComponentList = data;
                this.tableReload++;
                this.pageLoaded = true;
                this.state.loading = false;
            }).catch(error => {
                console.log("PunchPlates2:getPunchPlates2Callback():error:", error);
            });
        },
        getToolDataCallback(data) {
            this.fetchData(data.project_id);
        },
        launchPunchPlates(data) {
            this.state.loading = true;

            // XML Document
            let xml = document.implementation.createDocument(null, "PunchPlates");
            let elements = xml.getElementsByTagName("PunchPlates");

            // Options element
            let options = xml.createElement("Options");
            options.setAttribute("version", this.punchPlateUtilityVersion);
            if ('polygon-offset' in data) {
                options.setAttribute("polygonOffset", data['polygon-offset']);
            } else {
                options.setAttribute("polygonOffset", '0');
            }
            if ('filename-prefix' in data) {
                options.setAttribute("fileNamePrefix", data['filename-prefix']);
            } else {
                options.setAttribute("fileNamePrefix", '');
            }
            if ('label-prefix' in data) {
                options.setAttribute("labelPrefix", data['label-prefix']);
            } else {
                options.setAttribute("labelPrefix", '');
            }
            options.setAttribute("targetDir", this.dropBoxLocation);
            elements[0].appendChild(options);

            let noRowsSelected = true;
            this.processedComponentList.forEach(punchplate => {
                if (punchplate.selected) {
                    noRowsSelected = false;
                }
            });
            this.processedComponentList.forEach(p => {
                if (noRowsSelected || p.selected) {
                    let punchplate = xml.createElement("PunchPlate");
                    punchplate.setAttribute("definition", p.definition);
                    punchplate.setAttribute("thickness", p.thickness);
                    punchplate.setAttribute("quantity", p.instances_in_selection);
                    p['faces'].forEach(f => {
                        let face = xml.createElement("Face");
                        f['loops'].forEach(l => {
                            let loop = xml.createElement("Loop");
                            loop.setAttribute("outer", l.outer);
                            loop.setAttribute("circle", l.circle);
                            if (l.circle) {
                                let arccurve = xml.createElement("ArcCurve");
                                arccurve.setAttribute("x", l.arccurve.x);
                                arccurve.setAttribute("y", l.arccurve.y);
                                arccurve.setAttribute("radius", l.arccurve.radius);
                                loop.appendChild(arccurve);
                            } else {
                                l.vertices.forEach(v => {
                                    let vertex = xml.createElement("Vertex");
                                    vertex.setAttribute("x", v.x);
                                    vertex.setAttribute("y", v.y);
                                    loop.appendChild(vertex);
                                });
                            }
                            face.appendChild(loop);
                        });
                        f['folds'].forEach(f => {
                            let fold = xml.createElement("Fold");
                            fold.setAttribute("x1", f.x1);
                            fold.setAttribute("y1", f.y1);
                            fold.setAttribute("x2", f.x2);
                            fold.setAttribute("y2", f.y2);
                            face.appendChild(fold);
                        });
                        punchplate.appendChild(face);
                    });
                    elements[0].appendChild(punchplate);
                }
            });

            let sXml = (new XMLSerializer()).serializeToString(xml);
            sketchup.launchPunchPlates(process.env.VUE_APP_TEMP_DIR, process.env.VUE_APP_PUNCHPLATES_DIR, sXml);
        },
        launchPunchPlatesCallback() {
            this.state.loading = false;
        },
        selectAll() {
            sketchup.selectAll();
        },
        selectComponent(component) {
            if ('entity_id' in component) {
                sketchup.selectComponent(component.entity_id);
            } else {
                sketchup.selectComponents(component.entity_ids);
            }
        },
        setIgnoreFlipped(choices) {
            choices.forEach(choice => {
                this.ignoreFlipped[choice.name] = choice.value;
            });
        },
        zoomComponent(component) {
            sketchup.renderingOption('InactiveHidden', true);
            sketchup.renderingOption('InstanceHidden', true);
            if ('entity_id' in component) {
                sketchup.zoomExtents(component.entity_id);
            } else {
                sketchup.zoomExtents(component.entity_ids[0]);
            }
        },
        zoomReset() {
            sketchup.renderingOption('InactiveHidden', false);
            sketchup.renderingOption('InstanceHidden', false);
            sketchup.zoomExtents();
        },
    }
}
</script>

<style scoped>
</style>
